import { pickBy } from "lodash";

import prefixUrl from "../public-path";

export default function trackChargifyJsError({
  error,
  userId,
  category,
  email,
  value,
}) {
  const uri = prefixUrl("/rest/chargifyJsError");
  const payload = pickBy({
    error,
    userId,
    category,
    email,
    value,
    uri: window.location.href,
  });
  fetch(uri, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: { "Content-Type": "application/json" },
  }).catch(err => {
    console.log("Error calling ", uri);
    console.log(payload);
    console.log(err);
  });
}
