import gql from "graphql-tag";
import PropTypes from "prop-types";
import React from "react";
import { ApolloProvider, useQuery } from "react-apollo";

import { memberClient } from "./graphql";
import localizify, { registerInterpolationsFromConfig } from "./lib/i18n";

localizify.setLocale("en"); // hardcoded to EN for now. Could support user specified locale in the future

const appQuery = gql`
  query AppQuery {
    me @client {
      id
      email
      name {
        formatted
      }
      roles
      communityCode
    }
    community @client {
      name
      appName
      conciergeEmail
      conciergePhone
      googleAnalytics
      googleTagManager
    }
  }
`;

const AppWrapper = ({ children }) => {
  const { data } = useQuery(appQuery, {
    client: memberClient,
    returnPartialData: true,
  });

  registerInterpolationsFromConfig(data);

  return <ApolloProvider client={memberClient}>{children}</ApolloProvider>;
};

AppWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppWrapper;
