import { t } from "localizify";
import React from "react";
import { useHistory } from "react-router-dom";
import { Embed, Header, Modal } from "semantic-ui-react";

import useQueryParams from "../../lib/use-query-params";

export const VideoModal = () => {
  const history = useHistory();
  const queryParams = useQueryParams();

  function handleClose() {
    if (queryParams.has("video")) {
      queryParams.delete("video");
      history.replace(`?${queryParams.toString()}`);
    }
  }

  return (
    <Modal open={queryParams.has("video")} onClose={handleClose} closeIcon>
      <Modal.Header>
        <Header textAlign="center" as="h1">
          {t("articles.video_modal.title")}
        </Header>
      </Modal.Header>
      <Modal.Content>
        <Header textAlign="center" as="h5">
          {t("articles.video_modal.subtitle")}
        </Header>
        <Embed
          placeholder="https://storage.googleapis.com/yecg-web-assets/publishing/placeholder.png"
          url="https://theyec.wistia.com/embed/iframe/0g3vlzhhni?autoPlay=true&silentAutoPlay=false"
        />
      </Modal.Content>
    </Modal>
  );
};

export default VideoModal;
