import PropTypes from "prop-types";

import { namespacedT } from "../../lib/i18n";

const tPayment = namespacedT("onboard.credit_card");

const iFrameStyle = {
  width: "100%",
  height: "72px",
};

export const baseConfig = {
  // form type (possible values: ["card", "bank", "apple_pay", "gocardless"])
  type: "card",
  style: {
    "#chargify-card": iFrameStyle,
    "#chargify-month": iFrameStyle,
    "#chargify-first-name": iFrameStyle,
    "#chargify-last-name": iFrameStyle,
    field: {
      display: "inline-block",
      marginBottom: "0px",
    },
    message: {
      display: "none",
    },
  },

  fields: {
    firstName: {
      selector: "#chargify-first-name",
      label: tPayment("first_name_label"),
      required: true,
      message: tPayment("chargify_form_error_first_name"),
      style: {
        field: {
          margin: "0px",
        },
      },
    },
    lastName: {
      selector: "#chargify-last-name",
      label: tPayment("last_name_label"),
      required: true,
      message: tPayment("chargify_form_error_last_name"),
      style: {
        field: {
          margin: "0px",
        },
      },
    },
    number: {
      selector: "#chargify-card",
      label: tPayment("number_label"),
      placeholder: tPayment("placeholder_number"),
      required: true,
      message: tPayment("chargify_form_error_card"),
      style: {
        field: {
          maxWidth: "230px",
          margin: "0px",
        },
        input: {
          borderRadius: "4px 0 0 4px",
        },
      },
    },
    cvv: {
      selector: "#chargify-card",
      label: tPayment("cvv_label"),
      placeholder: "###",
      required: true,
      style: {
        label: {
          paddingRight: "24px",
          textAlign: "right",
        },
        field: {
          maxWidth: "60px",
          margin: "0px",
        },
        input: {
          borderRadius: "0 4px 4px 0",
          paddingLeft: "10px",
        },
      },
    },
    month: {
      selector: "#chargify-month",
      label: tPayment("month_label"),
      placeholder: tPayment("placeholder_month"),
      message: tPayment("chargify_form_error_expiration_date"),
      style: {
        field: {
          width: "45px",
          marginRight: "5px",
        },
      },
    },
    year: {
      selector: "#chargify-month",
      label: `/ ${tPayment("year_label")}`,
      placeholder: tPayment("placeholder_year"),
      style: {
        field: {
          width: "58px",
          marginRight: "5px",
        },
        label: {
          position: "relative",
          left: "-3px",
        },
      },
    },
    zip: {
      selector: "#chargify-month",
      label: tPayment("zip_label"),
      required: true,
      message: tPayment("chargify_form_error_zip_code"),
      style: {
        field: {
          width: "100px",
          marginLeft: "15px",
        },
        message: {
          whiteSpace: "nowrap",
        },
      },
    },
  },
};

export const chargifyFormProps = {
  formConfig: PropTypes.shape({
    publicKey: PropTypes.string.isRequired,
    serverHost: PropTypes.string.isRequired,
    securityToken: PropTypes.string.isRequired,
    paymentType: PropTypes.oneOf(["card", "bank", "apple_pay", "gocardless"]),
  }).isRequired,
  acceptedCards: PropTypes.arrayOf(
    PropTypes.oneOf([
      "VISA",
      "MASTERCARD",
      "AMERICAN_EXPRESS",
      "DISCOVER",
      "DINERS_CLUB",
      "JCB",
    ])
  ),
  onTokenCreate: PropTypes.func,
  onTokenError: PropTypes.func,
};

export const chargifyFormPropsDefaults = {
  acceptedCards: [],
  onTokenCreate: () => {},
  onTokenError: () => {},
};
