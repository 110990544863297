import gql from "graphql-tag";

export const typeDefs = gql`
  extend type Query {
    alerts: [Alert!]!
    availableCommunities: [AvailableCommunity]
    passwordTokenIsValid(token: String!): PasswordTokenIsValidResponse
    authStrategies(input: OneTimeCodeInput!): AuthStrategyResponse
    subscriptionBalance(input: RequestSubscriptionInput!): SubscriptionBalance
  }

  extend type Mutation {
    login(
      username: String!
      password: String!
      targetCommunity: String
    ): Boolean
    logout: Boolean
    tokenRefresh: Boolean
    alert(
      id: ID
      displayType: String
      title: String
      message: String!
      ttl: Int
    ): Boolean
    dismissAlert(id: String!): Boolean
    passwordForgot(username: String!): Boolean
    passwordReset(input: ResetPasswordInput): Boolean
    oneTimeCodeGenerate(input: OneTimeCodeInput!): Boolean
    oneTimeCodeVerify(input: OneTimeCodeVerifyInput!): OneTimeCodeVerified
    paySubscriptionByToken(input: AddSavedPaymentInput): String
  }

  enum OneTimeCodeChannel {
    EMAIL
    SMS
  }

  input OneTimeCodeInput {
    channel: OneTimeCodeChannel!
    to: String!
  }

  input OneTimeCodeVerifyInput {
    to: String!
    code: String!
  }

  input RequestSubscriptionInput {
    subscriptionId: ID!
    userId: ID!
  }

  type SubscriptionBalance {
    memberName: String
    outstandingBalance: Float
    showNoBalanceDue: Boolean
    renewalDate: String
    publicKey: String
    serverHost: String
  }

  input AddSavedPaymentInput {
    userId: ID!
    "Temp token from chargify.js"
    token: String!
  }

  type OneTimeCodeVerified {
    user: User!
    community: Community!
    authToken: String!
    refreshToken: String!
    roles: [String!]!
  }

  type AvailableCommunity {
    code: String
    name: String
    familiarName: String
  }

  type Alert {
    id: ID!
    displayType: String
    title: String
    message: String!
    ttl: Int
  }

  type App {
    name: String
    headerLogo: String
  }

  type NavItem {
    label: String
    link: String
    newWindow: Boolean
  }

  extend type ArticlePullQuote {
    isPersisting: Boolean
  }

  type OnboardingData {
    hasPaid: Boolean
    hasCredentials: Boolean
    onboardingComplete: Boolean
    greetingName: String
    paymentMembershipOffer: OnboardingPaymentMembershipOffer
    email: String
    showAddressStep: String
    disabled: Boolean
    userId: String
    braintreeClientToken: String
  }

  type OnboardingPaymentMembershipOffer {
    publicKey: String
    serverHost: String
    securityToken: String
    dues: Int
    initiationFee: Int
    savings: Int
    amountDue: Int
    renewsAt: String
    name: String
    description: String
  }

  type SubmitPaymentInput {
    dealId: String!
    productId: String!
    token: String!
    pricePointId: String
  }

  type CreatePasswordInput {
    username: String!
    password: String!
  }
`;

export const ALERT = gql`
  mutation DisplayAlert(
    $id: ID
    $displayType: String
    $title: String
    $message: String!
    $ttl: Int
  ) {
    alert(
      id: $id
      displayType: $displayType
      title: $title
      message: $message
      ttl: $ttl
    ) @client
  }
`;

export const DISMISS_ALERT = gql`
  mutation DismissAlert($alertId: String!) {
    dismissAlert(id: $alertId) @client
  }
`;

export const GET_ALERTS = gql`
  query {
    alerts @client {
      id
      message
      title
      displayType
      ttl
    }
  }
`;

export const USER = gql`
  query {
    me @client {
      profile {
        avatar
      }
      name {
        formatted
      }
    }
  }
`;
